module directives {
    export module reporting {
        interface IReportDocumentScope extends ng.IScope {
            type: Enum.EnumModelType,
            referenceId: number,
            version: number,
            model: Enum.EnumModelType,
            gvwReports: uiGrid.IGridOptions,
            gridApi: uiGrid.IGridApi,
            ShowGrid: boolean,
            IsLoading: boolean,
            Waiting: boolean,
            displayNotes(notes: string): void,
            downloadItemXLS(row: interfaces.reporting.IReport): void,
            downloadItem(row: interfaces.reporting.IReport): void,
            loadData(): ng.IPromise<any>,
            Save(): void,
            Send(): void
        }

        export class reportDocumentDirective implements ng.IDirective {
            restrict = 'E';
            templateUrl = 'templates/modules/reporting/reportDocumentView.html';
            scope = {
                type: "=",
                referenceId: "=",
                loadData: "&?",
                version: "=?",
            }
            
            constructor(public generalService: interfaces.applicationcore.IGeneralService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private reportService: interfaces.reporting.IReportService,
                private $q: ng.IQService,
                private bsLoadingOverlayService,
                private $timeout: ng.ITimeoutService,
                private $rootScope: interfaces.applicationcore.IRootScope,
                private $state: ng.ui.IStateService) {

            }

            link = ($scope: IReportDocumentScope, $element: ng.IAugmentedJQuery) => {
                $scope.ShowGrid = false;
                $scope.Waiting = false;
                $scope.gvwReports = {
                    data: [],
                    enableFiltering: true,
                    useExternalFiltering: false,
                    enableCellEdit: false,
                    useExternalSorting: false,
                    multiSelect: true,
                    enableColumnResizing: true,
                    enableRowSelection: true,
                    enableFullRowSelection: true,
                    useExternalPagination: false,
                    enableRowHeaderSelection: false,
                    enableHorizontalScrollbar: 2,
                    enablePinning: false,
                    rowEditWaitInterval: -1,
                    rowHeight: 36,
                    onRegisterApi: (gridApi) => {
                        $scope.gridApi = gridApi;                        
                    },
                    columnDefs: [{
                        name: "CODE",
                        displayName: "Document Code",
                        field: 'Code',
                        enableColumnMenu: true,
                        enableCellEdit: false,
                        enableCellEditOnFocus: false,
                            width: 150,
                            filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                            cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>'
                    }, {
                            name: "DESC",
                            displayName: "Description",
                            field: 'LocaleDescription',
                            enableColumnMenu: true,
                            enableCellEdit: false,
                            enableCellEditOnFocus: false,
                            width: 400,
                            filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                            cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>'
                        },
                        {
                            name: "LEVEL",
                            displayName: "Level",
                            field: 'Level',
                            enableColumnMenu: true,
                            enableCellEdit: false,
                            enableCellEditOnFocus: false,
                            width: 200,
                            filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                        },
                        {
                            name: "DocumentDetail",
                            displayName: "Document Detail",
                            field: "DetailLocaleDescription",
                            enableColumnMenu: true,
                            enableCellEdit: false,
                            enableCellEditOnFocus: false,
                            width: 400,
                            filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                            cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>'
                        }, {
                            name: "PDForXLS",
                            displayName: "",
                            enableFiltering: false,
                            cellTemplate: `
                                <div class="GridButton">
                                    <center>
                                        <button ng-if="row.entity.IsPDF" type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.downloadItem(row.entity)">
                                            <span class="fa fa-file-pdf-o" style="color: #cb0606; font-size:22px"></span>
                                        </button>
                                        <button ng-if="row.entity.IsXLS" type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.downloadItem(row.entity)">
                                            <span class="fa fa-file-excel-o" style="color: #247c4c; font-size:22px"></span>
                                        </button>
                                    </center>
                                </div>
                                `,
                            enableSorting: false,
                            enableColumnMenu: false,
                            width: 51,
                        }, {
                            name: "XLS",
                            displayName: "",
                            enableFiltering: false,
                            cellTemplate: `
                                <div class="GridButton">
                                    <center>
                                        <button ng-if="row.entity.AllowXLS && !row.entity.IsXLS" type="button" class="btn btn-default btn-sm" ng-click="grid.appScope.downloadItemXLS(row.entity)">
                                            <span class="fa fa-file-excel-o" style="color: #247c4c; font-size:22px"></span>
                                        </button>
                                    </center>
                                </div>
                                `,
                            enableSorting: false,
                            enableColumnMenu: false,
                            width: 51,
                        },]
                }

                if (angular.isDefined($scope.loadData)) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'document'
                    },
                        () => {
                            return $scope.loadData().then((result) => {
                                $scope.ShowGrid = true;
                                $scope.IsLoading = false;
                                $scope.gvwReports.data = result;
                                
                                
                            }, (failureMessage) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureMessage.data);
                            });
                        });
                }

                //This is for the normal file PDF/XLS to download as per their document type.
                $scope.downloadItem = (row: interfaces.reporting.IReport) => {                    
                    $scope.gridApi.selection.clearSelectedRows();
                    
                    this.$timeout(() => {
                        $scope.gridApi.selection.selectRow(row);
                    });

                    //Get the document
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'document'
                    },
                        () => {
                            var selectedIds: Array<number> = [];
                            
                            if (row.ParameterPageUrl) {
                                var IsAngular = row.ParameterPageUrl.indexOf("#!");

                                if (IsAngular >= 0) {
                                    var angularURL = row.ParameterPageUrl + "/" + $scope.referenceId + "/report/" + row.Id;
                                    var url = this.$state.href(angularURL, { consignmentId: $scope.referenceId, reportId: row.Id });
                                    window.open(angularURL, '_blank');
                                    return;
                                }
                                else {
                                    var url = row.ParameterPageUrl + "?Id=" + $scope.referenceId + "&reportId=" + row.Id;
                                    var encodedURL = encodeURIComponent(url);
                                    window.open("#!/IFrameWindow/" + encodedURL, '_blank');
                                    return;
                                }
                            }
                            else {
                                selectedIds.push(row.Id);
                            }
                            
                            $scope.Waiting = true;
                            $scope.IsLoading = true;

                            if (selectedIds.length > 0) {
                                this.reportService.saveToDocument(selectedIds, $scope.referenceId, $scope.type, $scope.version).then(() => {
                                    $scope.Waiting = false;
                                    $scope.IsLoading = false;
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                });
                            }
                            else {
                                $scope.Waiting = false;
                                $scope.IsLoading = false;
                            }
                        });
                }

                //This forces the document to be in XLS format.
                $scope.downloadItemXLS = (row: interfaces.reporting.IReport) => {
                    $scope.gridApi.selection.clearSelectedRows();

                    this.$timeout(() => {
                        $scope.gridApi.selection.selectRow(row);
                    });

                    //Get the document
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'document'
                    },
                        () => {
                            var selectedIds: Array<number> = [];
                            selectedIds.push(row.Id);

                            $scope.Waiting = true;
                            $scope.IsLoading = true;

                            this.reportService.saveToDocument(selectedIds, $scope.referenceId, $scope.type, $scope.version, true).then(() => {
                                $scope.Waiting = false;
                                $scope.IsLoading = false;
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            });
                        });
                }

                $scope.displayNotes = (detail: string) => {
                    this.$uibModal.open({
                        animation: true,
                        template: `
                        <div class="modal-content">
                            <div class="modal-header">
                                <h3 class="modal-title" id="modal-title">Detail</h3>
                            </div>
                            <div class="modal-body" id="modal-body">
                                <p ng-bind-html="detail"></p>
                            </div>
                            <div class="modal-footer">
                                <button class="btn btn-primary" type="button" ng-click="ok()">OK</button>
                            </div>
                        </div>
                    `,
                        controller: ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService) => {
                            $scope.detail = detail;

                            $scope.ok = () => {
                                $uibModalInstance.dismiss();
                            }
                        },
                        size: "lg",
                        resolve: {
                        }
                    });
                }

                $scope.Save = () => {
                    if (!angular.isDefined($scope.gridApi)) {
                        return;
                    }

                    var selectedRows: Array<interfaces.reporting.IReport> = $scope.gridApi.selection.getSelectedRows();
                    
                    if (!selectedRows || selectedRows.length <= 0) {
                        this.generalService.displayMessage("At least one document should be selected to Save", Enum.EnumMessageType.Warning);
                        return;
                    }
                    
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'document'
                    },
                        () => {
                            var selectedIds: Array<number> = [];

                            selectedRows.forEach((o) => {
                                if (o.ParameterPageUrl) {
                                    var IsAngular = o.ParameterPageUrl.indexOf("#!");

                                    if (IsAngular >= 0) {
                                        var angularURL = o.ParameterPageUrl + "/" + $scope.referenceId;
                                        var url = this.$state.href(angularURL, { consignmentId: $scope.referenceId});
                                        window.open(angularURL, '_blank');
                                        return;
                                    }
                                    else {
                                        var url = o.ParameterPageUrl + "?Id=" + $scope.referenceId + "&reportId=" + o.Id;
                                        var encodedURL = encodeURIComponent(url);
                                        window.open("#!/IFrameWindow/" + encodedURL, '_blank');
                                        return;
                                    }
                                }
                                else {
                                    selectedIds.push(o.Id);
                                }
                            });

                            $scope.Waiting = true;
                            $scope.IsLoading = true;

                            if (selectedIds.length > 0) {
                                this.reportService.saveToDocument(selectedIds, $scope.referenceId, $scope.type, $scope.version).then(() => {
                                    $scope.Waiting = false;
                                    $scope.IsLoading = false;
                                }, (errorResponse) => {
                                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                                });
                            }
                            else {
                                $scope.Waiting = false;
                                $scope.IsLoading = false;
                            }

                            if (selectedRows.length > 1) {
                                $scope.ShowGrid = false;
                                this.$timeout(() => {
                                    $scope.ShowGrid = true;
                                });
                            }
                        });
                }

                $scope.Send = () => {
                    if (!angular.isDefined($scope.gridApi)) {
                        return;
                    }

                    var selectedRows: Array<interfaces.reporting.IReport> = $scope.gridApi.selection.getSelectedRows();

                    if (!selectedRows || selectedRows.length <= 0) {
                        this.generalService.displayMessage("At least one document should be selected to Send to Repository", Enum.EnumMessageType.Warning);
                        return;
                    }

                    var reportIds: Array<number> = $scope.gridApi.selection.getSelectedRows().map<number>((o: interfaces.reporting.IReport) => {
                        return o.Id;
                    });

                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'document'
                    },
                        () => {
                            return this.reportService.saveToDocumentRepository(reportIds, $scope.referenceId, $scope.type, $scope.version).get((reponse: interfaces.applicationcore.IMessageHandler) => {
                                //this.generalService.displayMessageHandler(reponse);
                                angular.forEach(reponse.Messages, (o) => {
                                    angular.forEach(o.MessageItems, (n) => {
                                        this.$rootScope.Messages.push(n);
                                        if (n.TypeEnum === 3) {
                                            this.$timeout(() => {
                                                _.remove(this.$rootScope.Messages, (z) => {
                                                    return z === n;
                                                });
                                            }, 5000);
                                        }
                                    });
                                });

                                $scope.ShowGrid = false;
                                this.$timeout(() => {
                                    $scope.ShowGrid = true;
                                });
                            }, (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);

                                if (selectedRows.length > 1)
                                {
                                    $scope.ShowGrid = false;
                                    this.$timeout(() => {
                                        $scope.ShowGrid = true;
                                    });
                                }
                            }).$promise;
                        });
                }
            }

            static factory(): ng.IDirectiveFactory {
                const directive = (generalService, $uibModal, reportService, $q, bsLoadingOverlayService, $timeout, $rootScope, $state) => new reportDocumentDirective(generalService, $uibModal, reportService, $q, bsLoadingOverlayService, $timeout, $rootScope, $state);
                directive.$inject = ["generalService", "$uibModal", "reportService", "$q", "bsLoadingOverlayService", "$timeout", "$rootScope", "$state"];

                return directive;
            }
        }
        angular.module("app").directive("gtsReportDocument", reportDocumentDirective.factory());
    }
}